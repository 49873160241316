import * as React from 'react'
import { useState } from 'react'
import BackArrow from '../../components/back-arrow'
import { centerHeading } from '../../components/layout.module.css'
import { lightSwitch, lightSwitchPlate, lightSwitchToggleHole,
  lightSwitchToggle, lightSwitchToggleTip, lightSwitchToggleRidge,
  lightSwitchSocketShadow, lightSwitchScrewBox, lightSwitchScrewHoleTop,
  lightSwitchScrew, lightSwitchScrewHoleBottom, lightSwitchToggleOn,
  lightSwitchToggleTipOn, lightSwitchToggleRidgeOn, lightSwitchSocketShadowOn,
  lightSwitchUnscrew/*, lightSwitchScrewHoleWOW*/
} from '../../components/lights.module.css'

const LightSwitch = ({ position }) => {
  const isBrowser = () => typeof window !== "undefined" && typeof document !== "undefined";
  let setting = (isBrowser()) ? localStorage.getItem('preferred-theme') : 'dark';
  if (setting===null && (isBrowser())) {localStorage.setItem('preferred-theme','dark');}
  const [switchOn,setSwitch] = useState((setting==="dark") ? false : true);
  const setTheme = (switchOn) => {
    if (isBrowser()) {switchOn ? localStorage.setItem('preferred-theme','dark') : localStorage.setItem('preferred-theme','light');}
    const root = isBrowser() ? document.documentElement : "";
    if (switchOn) {
      root.style.setProperty('--color-back','#181818');
      root.style.setProperty('--color-front','white');
      root.style.setProperty('--color-border','#333333');
      root.style.setProperty('--color-highlight','gold');
    } else {
      root.style.setProperty('--color-back','#EDE9E3');
      root.style.setProperty('--color-front','#282828');
      root.style.setProperty('--color-border','#CCCCCC');
      root.style.setProperty('--color-highlight','darkslateblue');
    }
  };

  const [unscrewed,setUnscrewed] = useState(lightSwitchScrew);
  const screwed = (e) => {
    e.stopPropagation();
    setUnscrewed((unscrewed===lightSwitchScrew) ? lightSwitchUnscrew : lightSwitchScrew);
  };
  return (
    <div className={lightSwitch} onClick={()=>{setSwitch(!switchOn);setTheme(switchOn);}} role="presentation">
      <div className={lightSwitchPlate}>
        <div className={lightSwitchToggleHole}>
          <div className={switchOn ? lightSwitchToggleOn : lightSwitchToggle}>
            <div className={switchOn ? lightSwitchToggleTipOn : lightSwitchToggleTip}></div>
            <div className={switchOn ? lightSwitchToggleRidgeOn : lightSwitchToggleRidge}></div>
            <div className={switchOn ? lightSwitchSocketShadowOn : lightSwitchSocketShadow}></div>
          </div>
        </div>
        <div className={lightSwitchScrewBox}>
          <div className={lightSwitchScrewHoleTop}>
            <div className={unscrewed} onClick={e=>screwed(e)} role="presentation"></div>
          </div>
          <div className={lightSwitchScrewHoleBottom}>
            <div className={unscrewed} onClick={e=>screwed(e)} role="presentation"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const LightsPage = () => {
  return (
    <main>
      <title>Lights &ndash; Evan Gedrich</title>
      <h1 className={centerHeading}>Lights</h1>
      <LightSwitch />
      <BackArrow to="/apps">Apps</BackArrow>
    </main>
  )
}

export default LightsPage
