// extracted by mini-css-extract-plugin
export var lightSwitch = "lights-module--lightSwitch--ooA7K";
export var lightSwitchPlate = "lights-module--lightSwitch-plate--ZXSHJ";
export var lightSwitchToggleHole = "lights-module--lightSwitch-toggle-hole--YakjA";
export var lightSwitchScrewBox = "lights-module--lightSwitch-screw-box--AxZ9S";
export var lightSwitchScrewHoleTop = "lights-module--lightSwitch-screw-hole-top--XGdkD";
export var lightSwitchScrewHoleBottom = "lights-module--lightSwitch-screw-hole-bottom--EKv2X";
export var lightSwitchToggle = "lights-module--lightSwitch-toggle--v0efs";
export var lightSwitchToggleTip = "lights-module--lightSwitch-toggle-tip--uv9Ds";
export var lightSwitchToggleRidge = "lights-module--lightSwitch-toggle-ridge--7aclw";
export var lightSwitchSocketShadow = "lights-module--lightSwitch-socketShadow--lkqLY";
export var lightSwitchToggleOn = "lights-module--lightSwitch-toggle-on--w2F9H";
export var lightSwitchToggleTipOn = "lights-module--lightSwitch-toggle-tip-on--1ZDzw";
export var lightSwitchToggleRidgeOn = "lights-module--lightSwitch-toggle-ridge-on--y-h30";
export var lightSwitchSocketShadowOn = "lights-module--lightSwitch-socketShadow-on--7ttZC";
export var lightSwitchScrew = "lights-module--lightSwitch-screw--tPXeI";
export var lightSwitchUnscrew = "lights-module--lightSwitch-unscrew--3CIPA";
export var unscrew = "lights-module--unscrew--ZV3uO";
export var lightSwitchScrewHoleWOW = "lights-module--lightSwitch-screw-hole-WOW--XLE2+";
export var screwholeWOW = "lights-module--screwholeWOW--u1AeJ";